import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../config/Axios'; // Assuming Axios is configured for API requests
import { useSelector } from "react-redux";
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';


const ChatComponent = ({ receiverId, handleCloseChatting }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [senderName, setSenderName] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const senderId = useSelector((state) => state.auth.userId);
    const messagesEndRef = useRef(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketInstance = io('http://localhost:9000');
        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
        };
    }, []);

    useEffect(() => {
        socket?.emit('addUser', senderId);

        socket?.on('getUsers', (users) => {
            console.log('Users connected:', users);
        });

        socket?.on('getMessage', (data) => {
            if (data.senderId !== senderId) {
                setMessages((prev) => [...prev, { senderId: data.senderId, message: data.message }]);
            }
        });
    }, [socket, senderId]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const senderResponse = await axiosInstance.get(`/users/${senderId}`);
                setSenderName(senderResponse.data.name);

                const receiverResponse = await axiosInstance.get(`/users/${receiverId}`);
                setReceiverName(receiverResponse.data.name);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, [senderId, receiverId]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axiosInstance.get(`/messages/${senderId}/${receiverId}`);
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [senderId, receiverId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        setMessages((prev) => [...prev, { senderId, message: newMessage }]);
        setNewMessage('');

        socket.emit('sendMessage', {
            senderId,
            receiverId,
            message: newMessage,
        });

        try {
            await axiosInstance.post('/messages', {
                senderId,
                receiverId,
                message: newMessage,
            });
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="app-chatcomponent-chat-container d-flex p-0 flex-column">
            <div className="border-bottom app-chatcomponent-header text-center cursorpointer py-2" onClick={handleCloseChatting}>
                {`Chatting with ${receiverName}`} 
                <span className=" align-top float-end me-2">
                    <FontAwesomeIcon
                        // Call the passed function
                        icon={faChevronDown}
                        title="Close"
                    />
                </span>

            </div>

            <div className="messages flex-grow-1 overflow-auto p-3">
                {messages.length > 0 ? (
                    messages.map((msg, index) => (
                        <div
                            key={index}
                            className={msg.senderId === senderId ? 'app-chatanimation-message-sent' : 'app-chatcomponent-message-received'}
                        >
                            <p>{msg.message}</p>
                        </div>
                    ))
                ) : (
                    <p className="text-muted text-center">No messages yet. Start the conversation!</p>
                    
                )}
                <div ref={messagesEndRef}></div>
            </div>

            <div className="app-chatcomponent-chat-input d-flex align-items-center p-2 border-top">
                <input
                    type="text"
                    className="form-control me-2"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                />
                <button
                    onClick={handleSendMessage}
                    className="btn btn-primary"
                    disabled={!newMessage.trim()}
                >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </div>
    );
};

export default ChatComponent;
